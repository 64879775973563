import React from 'react'
import Social from './Social'
import data from '../data/en.json'

const Contacts = () => {
    const { iconData } = data

    return (
        <section className="section contacts" id="contacts">
            <div className="section-center contacts-center">
                <h2 className='contacts-title'>Contact Us</h2>
                <p className='contacts-description'>
                    Get in touch with us to discuss your project and start collaboration
                    <br/>We are always ready for new challenges and eager to bring your ideas to life
                </p>
                <ul className="contacts-icons">
                    {iconData.map((social, index) =>
                    (
                        <Social key={social.id} {...social} parentClassName="icon contacts-icon" />
                    ))}
                </ul>
                <a className="email" href="mailto:contact@nardapp.com">
                    contact@nardapp.com
                </a>
                <div className='contacts-bg'>
                    <div className='contacts diamond_bg'/>
                    <div className='contacts diamond_bg'/>
                    <div className='contacts diamond'/>
                    <div className='contacts diamond'/>
                </div>
                
            </div>
        </section>
    )
}

export default Contacts