import React, { useState } from 'react';

import NavLink from './NavLink';
import Social from './Social';
import data from '../data/en.json';
import logo from '../assets/images/logo-txt-dark.svg';

const Navbar = ({isMainPage}) => {
    const { navLinks, iconData } = data;
    const [menuIsOpen, setMenuIsopen] = useState(false);

    const toggleMenu = () => {
        setMenuIsopen(!menuIsOpen);
    }

    return (
        <>
            <nav className="navbar" >
                <div className="nav-center">
                    <div className="nav-header">
                        <a href='/'><img src={logo} className="nav-logo" alt="NardApp" /></a>
                        <button type="button" className="nav-toggle" id="nav-toggle" onClick={toggleMenu}>
                            <i className="fas fa-bars"></i>
                        </button>
                        <ul className={`nav-links ${menuIsOpen && "show-links"}`} id="nav-links">
                            <div className='close-btn' onClick={toggleMenu} />
                            {
                                navLinks.map((link, index) => {
                                    return (
                                        <NavLink 
                                            onClick={() => setMenuIsopen(false)}
                                            href={isMainPage ? link.path : `/${link.path }`}
                                            key={link.id} {...link} 
                                            itemClass="nav-link" 
                                        />
                                    )
                                })
                            }
                        </ul>
                    </div>

                    <ul className="nav-icons">
                        {iconData.map((social, index) =>
                        (
                            <Social key={social.id} {...social} parentClassName="nav-icon" />
                        ))}
                        <a href="#contacts" className="btn hero-btn">contact us</a>
                    </ul>
                </div>
            </nav >

        </>
    )
}
export default Navbar