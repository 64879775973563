import React from 'react'
import Service from './Service';
import Title from './Title'
import data from '../data/en.json'

const Services = () => {
    const { services } = data
    return (
        <section className="section section-center" id="services">
            <Title title="Our services" />
            <section id="services">
                <div className="section-center services-center">

                    {services.map((tour, index) => <Service key={tour.id} {...tour} />)}


                </div>
            </section>

        </section >
    )
}

export default Services