import React from 'react'

const Title = (props) => {
    const { title } = props;
    return (
        <div className="section-title">
            <h2>{title}</h2>
        </div>

    )
}

export default Title