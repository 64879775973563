import Advantage from './Advantage';
import React from 'react'
import data from '../data/en.json';

const Advantages = () => {
    const { advantages } = data;
    return (
        <section className="section advantages" id="advantages">
            <div className="section-center advantages-center">
                {advantages.map((service, index) => {
                    return (
                        <Advantage key={service.id} {...service} />
                    )
                })}

            </div>
        </section>
    )
}

export default Advantages