import React from 'react'

const Advantage = ({ icon, title, text }) => {
    return (
        <article className="advantage" >
            {/* <span className="advantage-icon"><i className={icon}></i></span> */}
            <div className="advantage-info">
                <h4 className="advantage-title">
                    <span className="advantage-icon"><i className={icon}></i></span>
                    {title}
                </h4>
                <p className="advantage-text">
                    {text}
                </p>
            </div>
        </article >
    )
}

export default Advantage