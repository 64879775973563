import React from 'react'

const Hero = () => {
    return (
        <section className="hero" id="about">
            <div className="hero-banner">
                <h3>👋🏻 Welcome to NardApp!</h3>
                <h1>
                    We are an innovative company 
                    <br/>specializing in the <span>development of web projects </span> 
                    in both <span>Web2</span> and <span>Web3</span> spheres
                </h1>
                <p>
                    Our mission is to create unique, functional, and high-quality solutions for our clients, 
                    helping them achieve their goals in the digital environment
                </p>
            </div>
            <div className='diamond-bg'/>
            <div className='diamond-bg'/>
            <div className='diamond'/>
            <div className='diamond'/>
            <div className='diamond'/>
            <div className='diamond'/>
        </section>
    )
}

export default Hero