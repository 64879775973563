import React from 'react'

const NavLink = ({ href, text, itemClass, onClick }) => {
    return (
        <li>
            <a href={href} className={itemClass} onClick={onClick}>{text}</a>
        </li >
    )
}

export default NavLink