import React from 'react'
import data from '../data/en.json'
import logo from '../assets/images/logo-txt-light.svg';

const Footer = () => {
    return (
        <footer className="section footer">
            <div className="section-center footer-center">
            <a href='/'><img src={logo} className="nav-logo" alt="backroads" /></a>
                <p className="copyright">
                    &copy;
                    <span id="date">{new Date().getFullYear()} </span>
                    NardApp.
                    All rights reserved.
                </p>
                <a className="email" href="mailto:contact@nardapp.com">
                    contact@nardapp.com
                </a>
            </div>
        </footer>
    )
}

export default Footer