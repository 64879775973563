import React from 'react'

const Service = ({ id, title, description, text }) => {
    return (

        < article className="service-card" key={id} >
            <div className="service-info">
                <div className="service-title">
                    <h3>{title}</h3>
                </div>
                <h4 className="service-description">
                    {description}
                </h4>
                {text.map(line => (<p>{line}</p>))}
                <div className="service-footer">
                    <a href="#contacts" className="btn">contact us</a>
                </div>
            </div>
        </article>
    )
}

export default Service